exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adopt-js": () => import("./../../../src/pages/adopt.js" /* webpackChunkName: "component---src-pages-adopt-js" */),
  "component---src-pages-adoptsubmitted-js": () => import("./../../../src/pages/adoptsubmitted.js" /* webpackChunkName: "component---src-pages-adoptsubmitted-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contracts-js": () => import("./../../../src/pages/contracts.js" /* webpackChunkName: "component---src-pages-contracts-js" */),
  "component---src-pages-deposit-js": () => import("./../../../src/pages/deposit.js" /* webpackChunkName: "component---src-pages-deposit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourdogs-js": () => import("./../../../src/pages/ourdogs.js" /* webpackChunkName: "component---src-pages-ourdogs-js" */),
  "component---src-pages-studservice-js": () => import("./../../../src/pages/studservice.js" /* webpackChunkName: "component---src-pages-studservice-js" */)
}

